(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('MonitoringRuleDetailController', MonitoringRuleDetailController);

    MonitoringRuleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'MonitoringRule'];

    function MonitoringRuleDetailController($scope, $rootScope, $stateParams, entity, MonitoringRule) {
        var vm = this;
        vm.monitoringRule = entity;
        vm.load = function (id) {
            MonitoringRule.get({id: id}, function(result) {
                vm.monitoringRule = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:monitoringRuleUpdate', function(event, result) {
            vm.monitoringRule = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
