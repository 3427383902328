(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('MonitoringRuleDeleteController',MonitoringRuleDeleteController);

    MonitoringRuleDeleteController.$inject = ['$uibModalInstance', 'entity', 'MonitoringRule'];

    function MonitoringRuleDeleteController($uibModalInstance, entity, MonitoringRule) {
        var vm = this;
        vm.monitoringRule = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            MonitoringRule.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
