(function () {
    "use strict";
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular.module("wamasysApp")

.constant("ENV", "prod")

.constant("MODE", "simple")

.constant("VERSION", "1.4.85")

;
})();
