(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('monitoring-rule', {
            parent: 'entity',
            url: '/monitoring-rule?page&sort&search',
            data: {
                authenticate: true,
                pageTitle: 'wamasysApp.monitoringRule.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/monitoring-rule/monitoring-rules.html',
                    controller: 'MonitoringRuleController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('monitoringRule');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('monitoring-rule-detail', {
            parent: 'entity',
            url: '/monitoring-rule/{id}',
            data: {
                authenticate: true,
                pageTitle: 'wamasysApp.monitoringRule.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/monitoring-rule/monitoring-rule-detail.html',
                    controller: 'MonitoringRuleDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('monitoringRule');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'MonitoringRule', function($stateParams, MonitoringRule) {
                    return MonitoringRule.get({id : $stateParams.id});
                }]
            }
        })
        .state('monitoring-rule.new', {
            parent: 'monitoring-rule',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/monitoring-rule/monitoring-rule-dialog.html',
                    controller: 'MonitoringRuleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                libelle: null,
                                deleted: false,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('monitoring-rule', null, { reload: true });
                }, function() {
                    $state.go('monitoring-rule');
                });
            }]
        })
        .state('monitoring-rule.edit', {
            parent: 'monitoring-rule',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/monitoring-rule/monitoring-rule-dialog.html',
                    controller: 'MonitoringRuleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['MonitoringRule', function(MonitoringRule) {
                            return MonitoringRule.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('monitoring-rule', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('monitoring-rule.delete', {
            parent: 'monitoring-rule',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/monitoring-rule/monitoring-rule-delete-dialog.html',
                    controller: 'MonitoringRuleDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['MonitoringRule', function(MonitoringRule) {
                            return MonitoringRule.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('monitoring-rule', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
