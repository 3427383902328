(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReportingGlobalGraphEauController', ReportingGlobalGraphEauController);

    ReportingGlobalGraphEauController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingGlobalGraphEauController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;
        vm.idDashboard = "38306f10-d87d-11e8-8320-c93d887536a8";//OLD 03848d60-ecaf-11e7-8569-d570740c277c
	    vm.filtreagence = true;

    }
    
    
    angular
    .module('wamasysApp')
    .controller('ReportingGlobalGraphConsEauGlobalController', ReportingGlobalGraphConsEauGlobalController);

    ReportingGlobalGraphConsEauGlobalController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce'];
	
	function ReportingGlobalGraphConsEauGlobalController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce) {
	    var vm = this;
	    vm.idDashboard = "03848d60-ecaf-11e7-8569-d570740c277c";
	    vm.filtreagence = false;

	}

    angular
    .module('wamasysApp')
    .controller('ReportingGlobalGraphStatContratController', ReportingGlobalGraphStatContratController);

    ReportingGlobalGraphStatContratController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce'];
	
	function ReportingGlobalGraphStatContratController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce) {
	    var vm = this;
	    vm.idDashboard = "2f4aaad0-f7c5-11e7-84cb-dd097765c84d";
	    vm.filtreagence = false;

	}
	

    angular
    .module('wamasysApp')
    .controller('ReportingGlobalGraphSyntheseController', ReportingGlobalGraphSyntheseController);

    ReportingGlobalGraphSyntheseController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce'];
	
	function ReportingGlobalGraphSyntheseController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce) {
	    var vm = this;
	    vm.idDashboard = "28265f70-4486-11e8-81c3-b55237bddc5a";
	    vm.filtreagence = true;

	}


    angular
    .module('wamasysApp')
    .controller('ReportingGlobalGraphSyntheseHebdoController', ReportingGlobalGraphSyntheseHebdoController);

    ReportingGlobalGraphSyntheseHebdoController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce'];
	
	function ReportingGlobalGraphSyntheseHebdoController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce) {
	    var vm = this;
	    vm.idDashboard = "d0f660b0-c7ac-11e8-8320-c93d887536a8";
	    vm.periodKibana = true;
	    vm.filtreagence = true;

	}

    angular
    .module('wamasysApp')
    .controller('ReportingGlobalGraphSummaryGlobalController', ReportingGlobalGraphSummaryGlobalController);

    ReportingGlobalGraphSummaryGlobalController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce'];
	
	function ReportingGlobalGraphSummaryGlobalController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce) {
	    var vm = this;
	    vm.idDashboard = "117dce80-c7ac-11e8-8320-c93d887536a8";
	    vm.filtreagence = false;

	}
	


    angular
    .module('wamasysApp')
    .controller('ReportingGlobalGraphInterAgenceController', ReportingGlobalGraphInterAgenceController);

    ReportingGlobalGraphInterAgenceController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce'];
	
	function ReportingGlobalGraphInterAgenceController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce) {
	    var vm = this;
	    vm.idDashboard = "d0ca0300-fb68-11e7-a63c-93391761857f";
	    vm.filtreagence = false;

	}
	
	

    angular
        .module('wamasysApp')
        .controller('ReportingGlobalGraphAnomalieWaterController', ReportingGlobalGraphAnomalieWaterController);

    ReportingGlobalGraphAnomalieWaterController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingGlobalGraphAnomalieWaterController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;
        vm.idDashboard = "98e9dee0-9afc-11e8-8320-c93d887536a8";
	    vm.filtreagence = true;

    }
    


    angular
        .module('wamasysApp')
        .controller('ReportingGlobalGraphAnomalieFinancesController', ReportingGlobalGraphAnomalieFinancesController);

    ReportingGlobalGraphAnomalieFinancesController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingGlobalGraphAnomalieFinancesController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;
        vm.idDashboard = "5c937b10-48c0-11e8-81c3-b55237bddc5a";
	    vm.filtreagence = true;

    }

    angular
        .module('wamasysApp')
        .controller('ReportingGlobalGraphSearchPaymentController', ReportingGlobalGraphSearchPaymentController);

    ReportingGlobalGraphSearchPaymentController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingGlobalGraphSearchPaymentController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;
        vm.idDashboard = "25d4a220-6dae-11e9-a255-eb470cf68f80";
	    vm.filtreagence = true;
	    vm.periodKibana = false;

    }

    angular
        .module('wamasysApp')
        .controller('ReportingGlobalGraphSummaryAnnualController', ReportingGlobalGraphSummaryAnnualController);

    ReportingGlobalGraphSummaryAnnualController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingGlobalGraphSummaryAnnualController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;
        vm.idDashboard = "1f372e20-83b5-11e9-a255-eb470cf68f80";
	    vm.filtreagence = false;
	    vm.periodKibana = true;
    }
	
	

    angular
        .module('wamasysApp')
        .controller('ReportingGlobalGeolocationController', ReportingGlobalGeolocationController);

    ReportingGlobalGeolocationController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingGlobalGeolocationController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;
        vm.idDashboard = "9b43b4f0-7037-11eb-836b-c9e6a4c376cd";//"b1a9d550-cd61-11e9-a255-eb470cf68f80";
	    vm.filtreagence = false;
	    vm.periodKibana = true;
    }


    angular
        .module('wamasysApp')
        .controller('ReportingGlobalEvenementController', ReportingGlobalEvenementController);

    ReportingGlobalEvenementController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingGlobalEvenementController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;
        vm.idDashboard = "62d2a520-78b0-11e9-a255-eb470cf68f80";
	    vm.filtreagence = false;
	    vm.periodKibana = true;
    }


    angular
        .module('wamasysApp')
        .controller('ReportingBudgetController', ReportingBudgetController);

    ReportingBudgetController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingBudgetController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;
        vm.idDashboard = "2b3dd5e0-b5ba-11ec-b05f-dfee97821c63";
	    vm.filtreagence = true;
	    vm.periodKibana = true;
    }


    angular
        .module('wamasysApp')
        .controller('ReportingBudgetGlobalController', ReportingBudgetGlobalController);

    ReportingBudgetGlobalController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingBudgetGlobalController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;
        vm.idDashboard = "5de23610-beef-11ec-b05f-dfee97821c63";
	    vm.filtreagence = false;
	    vm.periodKibana = true;
    }
    
    angular
        .module('wamasysApp')
        .controller('ReportingGlobalStatFinancialController', ReportingGlobalStatFinancialController);

    ReportingGlobalStatFinancialController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingGlobalStatFinancialController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;
        vm.idDashboard = "786ea980-eb9a-11e9-b27e-752c6f6f8c92";
	    vm.filtreagence = true;
	    vm.periodKibana = false;
    }

    angular
        .module('wamasysApp')
        .controller('ReportingGlobalDiscountController', ReportingGlobalDiscountController);

    ReportingGlobalDiscountController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingGlobalDiscountController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;
        vm.idDashboard = "a644d2d0-eb9a-11e9-b27e-752c6f6f8c92";
	    vm.filtreagence = false;
	    vm.periodKibana = false;
    }

    angular
        .module('wamasysApp')
        .controller('ReportingGlobalIOTSmartWaterMeterController', ReportingGlobalIOTSmartWaterMeterController);

    ReportingGlobalIOTSmartWaterMeterController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingGlobalIOTSmartWaterMeterController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;
        vm.idDashboard = "eeba3ae0-3876-11ea-b497-25fc6f82f62a";
	    vm.filtreagence = false;
	    vm.periodKibana = false;
    }

    angular
        .module('wamasysApp')
        .controller('ReportingGlobalIOTAlertMeterController', ReportingGlobalIOTAlertMeterController);

    ReportingGlobalIOTAlertMeterController.$inject = ['$scope', '$state', '$filter', 'ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingGlobalIOTAlertMeterController ($scope, $state, $filter,  ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;
        vm.idDashboard = "0f7c72c0-b860-11ef-97c7-891d46b98746";
	    vm.filtreagence = false;
	    vm.periodKibana = false;
    }
    
    

    angular
        .module('wamasysApp')
        .controller('ReportingGlobalMyDashboardController', ReportingGlobalMyDashboardController);

    ReportingGlobalMyDashboardController.$inject = ['$scope', '$state', '$filter', 'confDashboard','ParseLinks','Principal', 'AlertService','$sce', 'ReportingGlobalGraphEau'];

    function ReportingGlobalMyDashboardController ($scope, $state, $filter, confDashboard, ParseLinks,Principal, AlertService, $sce, ReportingGlobalGraphEau) {
        var vm = this;

        vm.endUrl = confDashboard.url;
		if (typeof confDashboard.filtreagence === 'undefined' || confDashboard.filtreagence === "false") {
			vm.filtreagence = false;
		} else {
			vm.filtreagence = true;
		}
		if (typeof confDashboard.periodKibana === 'undefined' || confDashboard.periodKibana === "false") {
			vm.periodKibana = false;
		} else {
			vm.periodKibana = true;
		}
    }
    
})();
