(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('MonitoringRule', MonitoringRule);

    MonitoringRule.$inject = ['$resource'];

    function MonitoringRule ($resource) {
        var resourceUrl =  'api/monitoring-rules/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryTypeRules': { 
            	method: 'GET', isArray: true,
                url :'api/type-rules'
            	},
            
            	
         'runMonitoringRules': {
             method: 'GET',
             url :'api/run-monitoring-rules'
         },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
