(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReportingCollectionListController', ReportingCollectionListController);

    ReportingCollectionListController.$inject = ['$scope', '$state', '$filter', 'ParseLinks', 'AlertService', 'ReportingCollectionList','Collecteur','ServiceVendu'];

    function ReportingCollectionListController ($scope, $state, $filter,  ParseLinks, AlertService, ReportingCollectionList,Collecteur,ServiceVendu) {
        var vm = this;
        vm.search=search;
        vm.collecteurs = Collecteur.query({ offset: 0,size: 1000,sort: 'login,asc'});
//        vm.date = {
//                startDate: $state.params.startDate?new Date($state.params.startDate):null,
//                endDate: $state.params.endDate?new Date($state.params.endDate):null
//
//        };

        vm.date = {
                startDate: moment().subtract(1, "month"),
                endDate: moment()
            };

        
        
        vm.serviceVendus = ServiceVendu.queryInclDeleted({ offset: 0,size: 1000,sort: 'libelle,asc'});

        function onSuccess(data, headers) {
            vm.reporting = data;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }
        
        function search () {
            if (vm.date.startDate && vm.date.endDate) {
               vm.reporting = null;
               var  collectorsParam = vm.collecteurNotPromise?vm.collecteurNotPromise.map(function(elem){ return elem.id;}).join(","):null;
               var  servicesVenduParam = vm.serviceVenduNotPromise?vm.serviceVenduNotPromise.map(function(elem){ return elem.id;}).join(","):null;
           
            	ReportingCollectionList.query({
	        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
	        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd"),
	        		collectors:collectorsParam,
	        		servicesVendu:servicesVenduParam
	            }, onSuccess, onError);
            }

        }

	      
        $scope.openRangeDateTimePicker = function() {
				$('#daterange1').data('daterangepicker').toggle();
		}
        
		$scope.getTotal = function(nomVar1, nomVar2) {
			
			var total = 0;

			if (vm.reporting != undefined) {
			    for (var i=0; i < vm.reporting.length; i++) {
			        if(nomVar1!= undefined){
						total = total + vm.reporting[i][nomVar1];
					}
					if(nomVar2!= undefined){
						total = total + vm.reporting[i][nomVar2];
					}
			    }
			
			}
			return total;
		}
		  
		$scope.getRatioCollecteTotal = function(clReporting) {
			var total = 0;

			if (clReporting != undefined) {
				total = ( clReporting.totalCollecteParCollecteur +clReporting.totalCorrectionPaiement) /  clReporting.totalACollecte  * 100;
			
			}
			return total;
		}
		
		$scope.getRatioCollecteFacture= function(clReporting) {
			var total = 0;

			if (clReporting!= undefined) {
				total = ( clReporting.totalCollecteParCollecteur +clReporting.totalCorrectionPaiement) /  clReporting.totalFacture  * 100;
			
			}
			return total;
		}
		
		
		$scope.getTotalRatioCollecteTotal = function() {
			var total = 0;


			if (vm.reporting != undefined) {

				var ttotalCollecteParCollecteur = 	$scope.getTotal('totalCollecteParCollecteur');
				var ttotalCorrectionPaiement = 	$scope.getTotal('totalCorrectionPaiement');
				var ttotalACollecte = 	$scope.getTotal('totalACollecte');
				
				total = ( ttotalCollecteParCollecteur +ttotalCorrectionPaiement) /  ttotalACollecte  * 100;
				
			}
			return total;
		}
		
		$scope.getTotalRatioCollecteFacture= function() {
			var total = 0;

			if (vm.reporting != undefined) {

				var ttotalCollecteParCollecteur = 	$scope.getTotal('totalCollecteParCollecteur');
				var ttotalCorrectionPaiement = 	$scope.getTotal('totalCorrectionPaiement');
				var ttotalFacture = 	$scope.getTotal('totalFacture');
				
				total = ( ttotalCollecteParCollecteur +ttotalCorrectionPaiement) /  ttotalFacture  * 100;
				
			}
			return total;
			
			
		}
		
	}
})();
