(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('MonitoringRuleController', MonitoringRuleController);

    MonitoringRuleController.$inject = ['$scope', '$state', 'MonitoringRule', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function MonitoringRuleController ($scope, $state, MonitoringRule, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll();

        
		var $dialog = $(
				'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:1%; overflow-y:visible;">' +
				'<div class="modal-dialog modal-m">' +
				'<div class="modal-content">' +
					'<div class="modal-header">'+
					'<h3 class="modal-title" >Run monitoring alert</h3>'+
					'<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'+
			      
					'</div>' +
					'<div class="modal-body">' +
						'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
					'</div>' +
				'</div></div></div>');
    	
		var options = {};
		var message = 'Please wait ! Action in progress.';
		var settings = $.extend({
			dialogSize: 'lg',
			progressType: '',
			onHide: null // This callback runs after the dialog was hidden
		}, options);
		$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
		$dialog.find('.progress-bar').attr('class', 'progress-bar');


		vm.fermerDialog = function(){
			  $dialog.modal('hide');
		}
        
        
        
        
        
        
        vm.runRules = function (){

			$dialog.find('.modal-body').html( "");
    		$dialog.modal();
        	MonitoringRule.runMonitoringRules({}, onSuccessrunRules, onErrorrunRules);
        	function onSuccessrunRules(data, headers) {
        	      var popupContent = '';

        	        for (var manager in data) {
        	        	if(manager.startsWith("$") || manager.startsWith("toJSON")){
        	        		continue;
        	        	}
        	            popupContent += '<h4>' + manager + '</h4>';
        	            var managerData = data[manager];

        	            if(!managerData){
        	            	continue;
        	            }
        	            for (var key in managerData) {
        	                popupContent += '<h5>' + key + '</h5>';
        	                popupContent += '<table class="table table-bordered">';
        	                popupContent += '<thead><tr>';

            	            if(!managerData[key] || ! managerData[key][0]){
            	            	continue;
            	            }
        	                // Construire les en-têtes dynamiquement
        	                var firstItem = managerData[key][0];

        	                for (var header in firstItem) {
        	                    popupContent += '<th>' + header + '</th>';
        	                }
        	                popupContent += '</tr></thead><tbody>';


        	                // Construire les lignes dynamiquement
        	                Array.prototype.forEach.call( managerData[key], function(item){
        	                    popupContent += '<tr>';
        	                    for (var value in item) {
        	                        popupContent += '<td>' + item[value] + '</td>';
        	                    }
        	                    popupContent += '</tr>';
        	                	
        	                }
        	                		
        	                );
        	                
        	                
//        	                managerData[key].forEach(function(item) {
//        	                    popupContent += '<tr>';
//        	                    for (var value in item) {
//        	                        popupContent += '<td>' + item[value] + '</td>';
//        	                    }
//        	                    popupContent += '</tr>';
//        	                });

        	                popupContent += '</tbody></table>';
        	            }
        	        }

        			$dialog.find('.modal-body').html( popupContent);
     
        	        
            }
            function onErrorrunRules(error) {
                AlertService.error(error.data.message);
            }
            
        }


        function loadAll () {
        	
            MonitoringRule.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.monitoringRules = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

    }
})();
